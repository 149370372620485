const getDefaultState = () => {
  return {
    activeTab: 0,

    initData: {}
  }
}

export default {
  namespaced: true,

  state: getDefaultState(),

  getters: {},

  mutations: {
    SET_ACTIVE_TAB (state, tabIndex) {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
      state.activeTab = tabIndex
    },
    RESET_STATE (state) {
      Object.assign(state, getDefaultState())
    },
    SET_INIT_DATA (state, payload) {
      state.initData = payload
    }
  },

  actions: {}
}
