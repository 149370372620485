<template>
  <div>
    <div class="vx-row mb-3">
      <div class="vx-col lg:w-9/12 w-full">
        <vx-card :is-refresh="isLoadingInitData">
          <vs-tabs alignment="fixed" v-model="activeTab">
            <vs-tab label="Detail" icon-pack="feather">
              <TabHeader :isActive="activeTab === 0" :initData="initData"/>
            </vs-tab>
          </vs-tabs>
        </vx-card>
      </div>
      <div class="vx-col lg:w-3/12 w-full mt-3 lg:mt-0">
        <vx-card :is-refresh="isLoadingInitData">
          <div class="flex items-center mb-base">
            <feather-icon icon="LayersIcon" svgClasses="h-5 w-5" class="mr-2"/>
            <h5 class="font-medium">Approval Progress</h5>
          </div>
          <ApprovalProgress
            ref="approvalProgress"
            type="IZIN-WARGA"
            :id-ref="$route.params.idPerijinanWarga"
            :isShowSigningAction="true"
            @signingClicked="onClickSigningAction"/>
        </vx-card>
      </div>
    </div>

    <!--modals-->
    <PerijinanWargaApprovalSigning
      :isActive.sync="modalSigning.active"
      :idPerijinanWarga="modalSigning.idPerijinanWarga"
      :status="modalSigning.status"
      @success="onSigned"/>
  </div>
</template>

<script>
import modulePerijinanWargaApproval from '@/store/modules/approvals/perijinan-warga-approval/perijinan-warga-approval.store.js'
import PerijinanWargaRepository from '@/repositories/estate/perijinan-warga-repository'
import _ from 'lodash'

export default {
  name: 'PerijinanWargaApprovalShow',
  components: {
    PerijinanWargaApprovalSigning: () => import('@/views/pages/approvals/perijinan-warga-approval/PerijinanWargaApprovalSigning'),
    TabHeader: () => import('@/views/pages/approvals/perijinan-warga-approval/parts/TabHeader'),
    ApprovalProgress: () => import('@/views/components/approval-progress/ApprovalProgress')
  },
  mounted () {
    this.getInitData()
  },
  data () {
    return {
      isLoadingInitData: false,
      initData: {
        header: {}
      },
      modalSigning: {
        active: false,
        idPerijinanWarga: this.$route.params.idPerijinanWarga,
        status: null
      }
    }
  },
  computed: {
    activeTab: {
      get () {
        return this.$store.state.approvals.perijinanWargaApproval.activeTab
      },
      set (value) {
        this.$store.commit('approvals/perijinanWargaApproval/SET_ACTIVE_TAB', value)
      }
    }
  },
  watch: {
    initData: {
      deep: true,
      handler (value) {
        this.commitInitDataToStore(value)
      }
    }
  },
  methods: {
    onSigned () {
      this.getInitData()
      this.$refs.approvalProgress.refresh()
    },

    getInitData () {
      this.isLoadingInitData = true

      const idPerijinanWarga = this.$route.params.idPerijinanWarga
      PerijinanWargaRepository.show(idPerijinanWarga)
        .then(response => {
          this.initData = response.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            console.log(error)
            this.$router.push({ name: '404' })
          } else {
            console.log(error)
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoadingInitData = false
        })
    },

    onClickSigningAction (status) {
      this.modalSigning.status = status
      this.modalSigning.active = true
    },

    commitInitDataToStore (value) {
      this.$store.commit('approvals/perijinanWargaApproval/SET_INIT_DATA', _.cloneDeep(value))
    }
  },
  beforeCreate () {
    this.$store.registerModule(['approvals', 'perijinanWargaApproval'], modulePerijinanWargaApproval)
  },
  beforeDestroy () {
    this.$store.commit('approvals/perijinanWargaApproval/RESET_STATE')
    this.$store.unregisterModule(['approvals', 'perijinanWargaApproval'])
  }
}
</script>
